@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';
































































.Login_root {
  max-width: 400px;
  margin: 0 auto;
}

.Login_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  margin: 0 0 25px;
  div {
    flex: 0 1 auto;
    a {
      color: #2af3f3;
      text-decoration: none;
    }
  }
}
